import React from 'react';
import styled from 'styled-components';

interface BlogPostProps {
  title: string;
  content: string;
  image: string;
}

const Post = styled.div`
  margin: 20px;
`;

const PostImageWrapper = styled.div`
  display: flex;
  place-content: center;
  width: '100%'
`;

const PostImage = styled.img`
  width: 80%;
  max-width: 600px;
  height: 400px;
  max-height: 500px;
  object-fit: contain;
  position: center;
`;

const PostContent = styled.div`
  text-align: left;
  margin-top: 20px;
  
  h2, h3, h4 {
    margin-top: 20px;
    color: #333;
  }

  p {
    margin-bottom: 15px;
    line-height: 1.6;
  }

  ul, ol {
    margin-left: 20px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
`;

export default function BlogPost ({ title, content, image }: BlogPostProps): React.ReactElement {
  return (
    <Post>
      <PostImageWrapper>
        <PostImage src={image} alt={title} />
      </PostImageWrapper>
      <h2>{title}</h2>
      <PostContent dangerouslySetInnerHTML={{ __html: content }} />
    </Post>
  )}


