import styled from 'styled-components';
import SphereButton from '../components/SphereButton';
import Layout from '../components/Layout';
import PageTitle from '../components/Title';
import animationCat from '../lotties/animated-cat.json';
import Animation from '../components/Animation';
import { TRANSLATIONS } from '../translation/constants/translations';
import { t } from '../translation';

const SphereContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  justify-items: center;
  gap: 10px;
  margin-top: 50px;
  width: 100%;
`;

export default function MainPage() {
    return (
        <Layout>
            <PageTitle title={`${t(TRANSLATIONS.home.title)} 🔥`} />
            <p style={{paddingLeft: "30px", fontSize: '20px', color: 'rgb(37, 113, 128)', fontWeight: "bold", maxWidth: '400px', width: '90%'}}>
                {t(TRANSLATIONS.home.text)}
            </p>
            <Animation animationData={animationCat} />
            <SphereContainer>
                <SphereButton title={t(TRANSLATIONS.buttons.magic_sphere)} link="/magic-sphere" />
                <SphereButton title={t(TRANSLATIONS.buttons.party_reasons)} link="/party-reasons" />
                <SphereButton title={t(TRANSLATIONS.buttons.interesting)} link="/interesting" />
            </SphereContainer>
        </Layout>
    );
};
