import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface SphereButtonProps {
  title: string;
  link: string;
}

const Sphere = styled(Link)`
  width: 250px;
  height: 250px;
  background-color: rgb(253, 139, 81);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

interface SphereButtonProps {
  title: string,
  link: string
}

 export default function SphereButton ({ title, link }: SphereButtonProps): React.ReactElement {
  return (
    <Sphere to={link}>
      {title}
    </Sphere>
  );
};
