import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
  children: React.ReactNode;
}

const PageWrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;    
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 80vw;
    max-width: 850px;
    background-color: rgba(242, 229, 191, 0.8);
    box-shadow: 0px 8px 20px gray;
`;

 export default function Layout ({ children }: LayoutProps): React.ReactElement {
  return (
    <PageWrapper>
      <Header />
      <ContentWrapper>
        {children}
      </ContentWrapper>
      <Footer />
    </PageWrapper>
  );
};
