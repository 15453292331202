import Lottie from 'lottie-react';
import styled from 'styled-components';

interface AnimationProps {
  animationData: object;
  width?: string;
  loop?: boolean;
}

const GifContainer = styled.div<{ width: string }>`
  display: flex;
  justify-content: center;
  width: ${({ width }) => width};
`;

 export default function Animation({ animationData, width = '100%', loop = true }: AnimationProps): React.ReactElement {
  return (
    <GifContainer width={width}>
        <Lottie animationData={animationData} loop={loop} autoplay={true}/>
    </GifContainer>
  );
};
