import React from 'react';
import styled from 'styled-components';

interface PartyReasonCardProps {
  id: number;
  title: string;
  image: string;
  text: string;
  link: string;
}

const Card = styled.div`
  border-radius: 16px;
  overflow: hidden;
  width: 300px;
  margin: 30px;
  background-color: rgba(37, 113, 128, 1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);

  &:hover {
    transform: scale(1.1);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 20px;
  background-color: rgba(37, 113, 128, 1);
  color: white;
`;

// const CardLink = styled.a`
//   text-decoration: none;
//   color: rgb(242, 229, 191);
//   font-weight: bold;
//   font-style: italic;
// `

 export default function PartyReasonCard ({ title = '', image= '', text = '', link = '' }: PartyReasonCardProps): React.ReactElement {
  return (
  <Card>
    <CardImage src={image} alt={title} />
    <CardContent>
      <h3>{title}</h3>
      <p>{text}</p>
      {/* <CardLink href={link}>Click! It might interest you</CardLink> */}
    </CardContent>
  </Card>
)};
