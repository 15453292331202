import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    position: relative;
    z-index: 0;
  }

  body::before {
    content: '';
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/backgrounds/space-background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.3;
    z-index: -1;
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyles;