import React, { useState } from 'react';
import BlogPost from '../components/BlogPost';
import { blogPosts } from '../data/blogPosts';
import Layout from '../components/Layout';
import PageTitle from '../components/Title';
import AskAgainButton from '../components/AskAgainButton';
import Animation from '../components/Animation';
import animationLemon from '../lotties/fun-lemon.json'
import { t } from '../translation';
import { TRANSLATIONS } from '../translation/constants/translations';

const getRandomPost = () => {
  const randomId = Math.floor(Math.random() * blogPosts.length) || 0;
  return blogPosts[randomId];
};

export default function InterestingPage(): React.ReactElement {
  const [key, setKey] = useState(0);
  const randomPost = getRandomPost();

  const handleReset = () => {
    setKey(prev => prev + 1);
  };
  
  return (
      <Layout>
        <PageTitle title={`${t(TRANSLATIONS.interesting.title)} ⭒˚.🪐 ⋆`} />
        <Animation animationData={animationLemon} width={'200px'} loop={true} />
        <BlogPost key={key} {...randomPost} />
        <AskAgainButton onClick={handleReset} label={t(TRANSLATIONS.buttons.read_more)} />
        <p></p>
        {/* <p key={key}></p> */}
      </Layout>
)};
