import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MagicSpherePage from './pages/MagicSpherePage';
import PartyReasonsPage from './pages/PartyReasonsPage';
import GlobalStyles from './styles/GlobalStyles';
import MainPage from './pages/MainPage';
import InterestingPage from './pages/InterestingPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

const App: React.FC = () => (
  <Router>
    <GlobalStyles />
    <Routes>
    <Route path="/" element={<MainPage />} />
        <Route path="/magic-sphere" element={<MagicSpherePage />} />
        <Route path="/party-reasons" element={<PartyReasonsPage />} />
        <Route path="/interesting" element={<InterestingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route path="*" element={<Navigate to="/" />} /> 
    </Routes>
  </Router>
);

export default App;
