import React, { useState } from 'react';
import styled from 'styled-components';
import { changeLanguage, LANGUAGES } from '../translation';
import { getLanguage } from '../translation/utils/getLanguage';

const Button = styled.button<{ active: boolean }>`
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: rgb(242, 229, 191);
  text-decoration: none;
  opacity: ${({ active }) => (active ? 1 : 0.8)};
  background: rgba(242, 229, 191, 0.1);
  border: 1px solid rgb(253, 139, 81);
  border-radius: 4px;
  margin: 0 4px;
  padding: 4px;
  cursor: pointer;
`;

export default function LanguageSelector(): React.ReactElement {
    const initLang: LANGUAGES = getLanguage() || 'EN';
    const [language, setLanguage] = useState(initLang);

  const handleChange = (lang: LANGUAGES): void => {
    setLanguage(lang);
    changeLanguage(lang);
  };

  return (
    <div>
      <Button active={language === LANGUAGES.EN} onClick={() => handleChange(LANGUAGES.EN)}>
        EN
      </Button>
      <Button active={language === LANGUAGES.UA} onClick={() => handleChange(LANGUAGES.UA)}>
        UA
      </Button>
    </div>
  );
}
