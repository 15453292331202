import { TRANSLATIONS } from "../translation/constants/translations";

export const answers = [
  TRANSLATIONS.answers.yes,
  TRANSLATIONS.answers.no,
  TRANSLATIONS.answers.definitely,
  TRANSLATIONS.answers.ask_later,
  TRANSLATIONS.answers.no_way,
  TRANSLATIONS.answers.certain,
  TRANSLATIONS.answers.doubtful,
  TRANSLATIONS.answers.maybe,
  TRANSLATIONS.answers.no_ask,
  ];
  