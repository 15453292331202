import React from 'react';
import styled from 'styled-components';

interface PageTitleProps {
  title: string;
}

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  // color: rgb(203, 96, 64);
  color: rgb(37, 113, 128);
`;

 export default function PageTitle({ title }: PageTitleProps): React.ReactElement {
  return (
    <Title>{title}</Title>
  );
};
