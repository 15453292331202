import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { t } from '../translation';
import { TRANSLATIONS } from '../translation/constants/translations';

const FooterContainer = styled.div`
  background-color: rgb(37, 113, 128);
  padding: 20px 20px;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const StyledLink = styled(Link)`
  color: rgb(242, 229, 191);
  text-decoration: none;
  font-size: 14px;
  &:hover {
    color: white;
  }
`;

export default function Footer(): React.ReactElement {
  return (
    <FooterContainer>
      <StyledLink to="/">{t(TRANSLATIONS.links.home)}</StyledLink>
      <NavLinks>
        <StyledLink to="/privacy-policy">{t(TRANSLATIONS.links.privacy_policy)}</StyledLink>
      </NavLinks>
    </FooterContainer>
  );
};
