export const blogPosts = [
  {
    id: 1,
    title: "Make this day special",
    content: `
      <h3>Start your day with intention</h3>
      <p>Try imagining yourself as a character in a story. Suddenly, even ordinary moments feel special. Picture your morning coffee as the start of a new chapter—the sunlight, the warmth, the sounds around you become vivid details in your narrative.</p>
      <p>Every person you meet, from the barista to a stranger on the bus, plays a role in your plot. Pay attention, and life can feel more like an adventure. And if things get tough, remember, you’re the protagonist, overcoming challenges and growing along the way.</p>
      <p><strong>Each day can be a story worth telling!</strong></p>
    `,
    image: "/images/create-art-day.jpg",
  },
  {
    id: 2,
    title: "Take a 5-minute dance break",
    content: `
      <h3>Dance like nobody's watching</h3>
      <p>Pick any song that makes you want to move—maybe it’s a guilty pleasure pop hit or something nostalgic. Set a timer and dance like no one’s watching! You don’t need to be a pro—just move however feels good.</p>
      <ul>
        <li>Put on “Happy” by Pharrell or “Uptown Funk” by Bruno Mars and groove around your room.</li>
        <li>Try a TikTok dance challenge just for fun or mimic the moves in a music video.</li>
      </ul>
      <p><em>Why it’s worth it:</em> Moving your body releases endorphins, which instantly boost your mood. Plus, a quick dance session can give you an energy boost for the rest of the day.</p>
    `,
    image: "/images/dance-day.jpg",
  },
  {
    id: 3,
    title: "Send a random compliment",
    content: `
      <h3>Brighten someone's day</h3>
      <p>Think of a friend, family member, or even coworker, and send them a kind message out of the blue. Compliments don’t have to be deep—just honest and heartfelt.</p>
      <ul>
        <li>“You have the best laugh, it’s contagious!”</li>
        <li>“I admire how you handle tough situations—you’re so strong!”</li>
      </ul>
      <p><em>Why it’s worth it:</em> Compliments not only brighten someone’s day but also create a positive connection, and spreading positivity is a great way to lift your own spirits.</p>
    `,
    image: "/images/smile-day.jpg",
  },
  {
    id: 4,
    title: "Step outside for a breath of fresh air",
    content: `
      <h3>Take a moment to reset</h3>
      <p>Whether it’s a quick walk around the block, standing on your balcony, or even just opening a window for some fresh air, getting outside helps reset your mind.</p>
      <ul>
        <li>Take your coffee break outside, even for just 5 minutes.</li>
        <li>Step out and take in the view of the sky or nearby trees, noticing the colors and sounds around you.</li>
      </ul>
      <p><em>Why it’s worth it:</em> Spending even a few minutes outdoors can reduce stress and clear your mind, making you feel more centered and ready to tackle the rest of your day.</p>
    `,
    image: "/images/nature-walk-day.jpg",
  },
  {
    id: 5,
    title: "Make a funny face at yourself in the mirror",
    content: `
      <h3>Laugh at yourself</h3>
      <p>Walk up to a mirror and pull the goofiest face you can—stick out your tongue, puff up your cheeks, or make a silly grin. Seeing your reflection acting goofy is sure to spark a laugh.</p>
      <ul>
        <li>Try mimicking animal faces—think fish, gorilla, or owl!</li>
        <li>Pull a ridiculous face, then pretend you're in a slow-motion replay while you laugh at yourself.</li>
      </ul>
      <p><em>Why it’s worth it:</em> Laughter triggers the release of feel-good chemicals in your brain, helping to break the monotony of the day and giving you a little burst of joy.</p>
    `,
    image: "/images/smile-day.jpg",
  },
  {
    id: 6,
    title: "Send a 'thank you' note",
    content: `
      <h3>Express your gratitude</h3>
      <p>Think of someone who’s made a positive impact on your life recently. Send them a quick text, email, or even a physical note expressing your gratitude. It doesn’t have to be long—just a few words.</p>
      <ul>
        <li>“Thank you for being there when I needed it.”</li>
        <li>“I appreciate how much you’ve helped me with [specific task].”</li>
      </ul>
      <p><em>Why it’s worth it:</em> Expressing gratitude strengthens relationships and makes you feel more grounded and appreciative of the people in your life.</p>
    `,
    image: "/images/compliment-day.jpg",
  },
  {
    id: 7,
    title: "Take a 'mindful minute'",
    content: `
      <h3>Practice mindfulness</h3>
      <p>Set a timer for one minute. Close your eyes and take slow, deep breaths in through your nose and out through your mouth. Focus only on your breathing, and gently bring your focus back to the sensation of air filling your lungs.</p>
      <ul>
        <li>Inhale deeply for a count of 4, hold for 4, then exhale for 4—repeat.</li>
        <li>Focus on how the air feels as it enters and leaves your body.</li>
      </ul>
      <p><em>Why it’s worth it:</em> This short meditation reduces stress and calms your mind, helping you feel more present and centered in just a minute.</p>
    `,
    image: "/images/self-care-day.jpg",
  },
  {
    id: 8,
    title: "Play your favorite upbeat song",
    content: `
      <h3>Music to lift your mood</h3>
      <p>Pick a song that never fails to lift your mood and hit play. Whether you’re at home, in the car, or taking a break at work, let the music take over. Sing along, hum, or tap your feet to the beat.</p>
      <ul>
        <li>Blast “Don’t Stop Me Now” by Queen when you need a quick mood lift.</li>
        <li>Try “I Gotta Feeling” by The Black Eyed Peas and let the upbeat rhythm energize you.</li>
      </ul>
      <p><em>Why it’s worth it:</em> Music is a powerful mood booster, and the right song can instantly change your energy, leaving you feeling happier and more motivated.</p>
    `,
    image: "/images/throwback-music-day.jpg",
  },
  {
    id: 9,
    title: "Start a small 'happy jar'",
    content: `
      <h3>Collect small moments of joy</h3>
      <p>Find a jar, and every time something good happens—no matter how small—write it down on a piece of paper and add it to the jar. Over time, you’ll have a jar full of happy memories to revisit whenever you need a pick-me-up.</p>
      <ul>
        <li>“Had a nice walk in the park today, felt calm and peaceful.”</li>
        <li>“Tried a new coffee flavor today, and it was amazing!”</li>
      </ul>
      <p><em>Why it’s worth it:</em> Focusing on the positive moments throughout your day shifts your mindset towards gratitude.</p>
    `,
    image: "/images/pet-appreciation.jpg",
  },
  {
    id: 10,
    title: "Compliment a stranger",
    content: `
      <h3>Spread kindness</h3>
      <p>Take a moment to compliment a stranger. It could be as simple as “I love your shoes!” or “That jacket looks great on you.”</p>
      <ul>
        <li>“You have such a warm smile!”</li>
        <li>“Your outfit looks awesome!”</li>
      </ul>
      <p><em>Why it’s worth it:</em> Complimenting someone else boosts your own mood and creates a moment of connection that brightens both of your days.</p>
    `,
    image: "/images/superhero-movie-day.jpg",
  },
  {
    id: 11,
    title: "Surprise someone with a small act of kindness",
    content: `
      <h3>Brighten someone's day</h3>
      <p>Think of someone in your life—maybe a friend, family member, or even a coworker—and do something thoughtful for them. It could be grabbing their favorite snack or writing them a little note.</p>
      <ul>
        <li>Bring your coworker their favorite coffee as a surprise.</li>
        <li>Offer to walk a neighbor’s dog or help someone carry their groceries.</li>
      </ul>
      <p><em>Why it’s worth it:</em> Acts of kindness create happiness for both the giver and receiver, making the day feel brighter and more connected.</p>
    `,
    image: "/images/virtual-hangout-day.jpg",
  },
];
