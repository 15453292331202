import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import { t } from '../translation';
import { TRANSLATIONS } from '../translation/constants/translations';

const HeaderContainer = styled.div`
  background-color: rgb(37, 113, 128);
  padding: 20px 20px;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const StyledLink = styled(Link)`
  color: rgb(242, 229, 191);
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  &:hover {
    color: white;
  }
`;

export default function Header(): React.ReactElement {
  return (
    <HeaderContainer>
      <NavLinks>
        <StyledLink to="/">{t(TRANSLATIONS.links.home)}</StyledLink>
        <LanguageSelector />
      </NavLinks>
      <NavLinks>
        <StyledLink to="/magic-sphere">{t(TRANSLATIONS.links.magic_sphere)}</StyledLink>
        <StyledLink to="/party-reasons">{t(TRANSLATIONS.links.party_reasons)}</StyledLink>
        <StyledLink to="/interesting">{t(TRANSLATIONS.links.interesting)}</StyledLink>
      </NavLinks>
    </HeaderContainer>
  );
};
