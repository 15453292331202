// import { RulesEn } from "src/translation/constants/rules";

export const TRANSLATIONS = {
    home: {
        title: {
            en: "Do whatever you want",
            ua: "Роби, що забажаєш",
        },
        text: {
            en: "Try! Choose one of the following spheres below that you like the most.",
            ua: "Спробуй! Вибери одну зі сфер, що нижче: яка найбільше сподобається.",
        },
    },
    magic_sphere: {
        title: {
            en: "Ask the Magic Sphere",
            ua: "Запитай y Чарівної кулі",
        },
    },
    party_reasons: {
        title: {
            en: "Get 3 remarkable reasons to celebrate today",
            ua: "Тримай 3 чудові причини для святкування сьогодні",
        },
        text_1: {
            en: "All the reasons here are carefully picked for you to encourage your aspiration to make this day and more enjoyable.",
            ua: "Усі причини тут ретельно підібрані для того, щоб підкріпити твоє бажання зробити цей день приємнішим.",
        },
        text_2: {
            en: "Choose one of these or be a daredevil and celebrate them all!",
            ua: "Вибери одну із них або будь сміливцем і відсвяткуй їх всі!",
        },
    },
    blog: {
        title: {
            en: "The cutest blog ever",
            ua: "The cutest blog ever",
        },
    },
    interesting: {
        title: {
            en: "It certainly will make your day",
            ua: "Це точно визначить настрій цього дня",
        },
    },
    links: {
        home: { en: "Fun & Friendly", ua: "Fun & Friendly" },
        magic_sphere: { en: "Magic Sphere", ua: "Магічна куля" },
        party_reasons: { en: "Party reasons", ua: "Свято сьогодні" },
        interesting: { en: "Read it", ua: "Прочитай це" },
        privacy_policy: { en: "Our Privacy Policy", ua: "Політика конфіденційності сайту" },
    },
    buttons: {
        ask_again: { en: "Ask one more time", ua: "Запитати ще" },
        magic_click: { en: "Click me!", ua: "Натискай!" },
        magic_sphere: { en: "Magic Sphere", ua: "Магічна куля" },
        party_reasons: { en: "Party reasons", ua: "Що би відсвяткувати" },
        interesting: { en: "Check, if you know this ...", ua: "Перевір, чи ти вже знаєш це ..." },
        read_more: { en: "I want to read a new one", ua: "Хочу прочитати ще" },
    },
    answers: {
        yes: { en: "Yes.", ua: "Так." },
        no: { en: " No.", ua: "Ні." }, 
        definitely: { en: "Yes, definitely!", ua: "Так, однозначно!" },
        ask_later: { en: "Ask again later.", ua: "Запитай ще раз пізніше." },
        no_way: { en: "No way!", ua: "Ні в якому разі!" },
        certain: { en: "It is certain.", ua: "Це точно." },
        doubtful: { en: "Very doubtful.", ua: "Дуже сумнівно." }, 
        maybe: { en: "There is a likelihood.", ua: "Є така ймовірність." }, 
        no_ask: { en: "Don't ask about it.", ua: "Не запитуй про це." }, 
    }    
};
