import { LANGUAGES } from "../types/languages";

const defaultLanguage = LANGUAGES.EN;

export const getLanguage = (): LANGUAGES => {
    const localStorageLang = localStorage.getItem(
        "language"
    ) as LANGUAGES | null;

    if (
        localStorageLang &&
        Object.values(LANGUAGES).includes(localStorageLang)
    ) {
        return localStorageLang;
    }

    const cookieLang = document.cookie
        .split("; ")
        .find((row) => row.startsWith("language="));
    if (cookieLang) {
        const lang = cookieLang.split("=")[1] as LANGUAGES;
        if (Object.values(LANGUAGES).includes(lang)) {
            return lang;
        }
    }

    const browserLang = navigator.language.split("-")[0] as LANGUAGES;
    if (Object.values(LANGUAGES).includes(browserLang)) {
        return browserLang;
    }

    return defaultLanguage;
};
