import React, { useState, useEffect } from 'react';
import PartyReasonCard from '../components/PartyReasonCard';
import { partyReasons } from '../data/partyReasons';
import Layout from '../components/Layout';
import PageTitle from '../components/Title';
import { t } from '../translation';
import { TRANSLATIONS } from '../translation/constants/translations';

const getRandomPartyReasons = (reasons: typeof partyReasons, count: number) => {
  const shuffled = [...reasons].sort(() => 0.5 - Math.random()); 
  return shuffled.slice(0, count); 
};

export default function PartyReasonsPage(): React.ReactElement {
  const [randomReasons, setRandomReasons] = useState(getRandomPartyReasons(partyReasons, 3));

  useEffect(() => {
    const newRandomReasons = getRandomPartyReasons(partyReasons, 3);
    setRandomReasons(newRandomReasons);
  }, []);

  return (
      <Layout>
        <PageTitle title={t(TRANSLATIONS.party_reasons.title)} />

        <p style={{paddingLeft: "30px", fontSize: '20px', color: 'rgb(203, 96, 64)', fontWeight: "bold", maxWidth: '500px', width: '80%'}}>
          <p>{t(TRANSLATIONS.party_reasons.text_1)}</p>
          <p>{t(TRANSLATIONS.party_reasons.text_2)}</p>                 
        </p>

        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {randomReasons.map((reason) => (
            <PartyReasonCard
              key={reason?.id}
              id={reason?.id || 1}
              title={reason?.title || 'Untitled'}
              image={reason?.image || '/images/default.jpg'}
              text={reason?.text || 'No description available'}
              link={reason?.link || '#'}
            />
          ))}
        </div>
      </Layout>
  );
};
