import Layout from "../components/Layout";

export default function PrivacyPolicyPage() {
  return (
    <Layout>
        <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px', lineHeight: '1.6', color: '#333', padding: '20px' }}>
        <h1>Privacy Policy</h1>
        <p>Last updated: 31.10.2024</p>
        <p>This Privacy Policy describes how we collect, use, and disclose your personal information when you visit our website and/or use our services. By using this site, you agree to the collection and use of information in accordance with this policy.</p>

        <h2>Information We Collect</h2>
        <p>We collect various types of information in order to provide and improve our services to you. The information we collect includes:</p>

        <h3>1. Personal Identification Information</h3>
        <p>When you use our website, we may ask for certain personally identifiable information, including but not limited to:</p>
        <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Other contact details</li>
        </ul>

        <h3>2. Usage Data</h3>
        <p>We also collect information on how the website is accessed and used, which may include your IP address, browser type, browser version, the pages you visit on our site, the time and date of your visit, the time spent on those pages, and other diagnostic data.</p>

        <h3>3. Cookies and Tracking Technologies</h3>
        <p>We use cookies and similar tracking technologies to track activity on our website and hold certain information. Cookies are small data files placed on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our site.</p>

        <h2>How We Use Your Information</h2>
        <p>We use the collected data for various purposes, including:</p>
        <ul>
            <li>To provide and maintain our website and services</li>
            <li>To notify you about changes to our services</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve our website</li>
            <li>To monitor the usage of our website</li>
            <li>To detect, prevent, and address technical issues</li>
            <li>To comply with legal obligations</li>
        </ul>

        <h2>Google AdSense and Cookies</h2>
        <p>We use Google AdSense to display ads on our website. Google uses cookies to help serve ads that are personalized to your interests. Google may collect and use information about your visits to this and other websites to provide advertisements that may be of interest to you. You can opt out of personalized advertising by visiting the <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">Google Ads Settings page</a>.</p>

        <h2>Third-Party Services</h2>
        <p>We may use third-party services to help analyze how our website is used, and these services may collect information about your browsing behavior. These third parties may have their own privacy policies governing how they handle your data. We recommend reviewing their policies for a better understanding of their practices.</p>

        <h2>Security of Your Data</h2>
        <p>The security of your personal information is important to us. However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. It is advised that you review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <ul>
            <li>Email: info@malko.tech</li>
        </ul>
        </div>
    </Layout>
  );
};

