import styled from 'styled-components';

const AskAgainButtonItem = styled.button`
  background-color: rgba(37, 113, 128, 1);
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: rgba(37, 113, 128, 0.8);
  }
`;

interface onClickActionProps {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
    label: string
}

export default function AskAgainButton ({onClick, label}: onClickActionProps): React.ReactElement {
  return (
        <AskAgainButtonItem onClick={onClick}>{label}</AskAgainButtonItem>
  );
};

