import { LANGUAGES, Language } from "../types/languages";
import { getLanguage } from "./getLanguage";

const currentLanguage = getLanguage();

export const t = (translations: Language): string => {
    return translations[currentLanguage] ?? translations.en ?? translations;
};

export const changeLanguage = (languageKey: LANGUAGES): void => {
    localStorage.setItem("language", languageKey);
    document.cookie = `language=${languageKey}; path=/`;
    window.location.reload();
};
