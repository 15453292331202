import React, { useState } from 'react';
import MagicSphere from '../components/MagicSphere';
import Layout from '../components/Layout';
import PageTitle from '../components/Title';
import animationAsk from '../lotties/ask-question.json';
import Animation from '../components/Animation';
import AskAgainButton from '../components/AskAgainButton';
import { t } from '../translation';
import { TRANSLATIONS } from '../translation/constants/translations';
// import AdSense from 'react-adsense';

export default function MagicSpherePage(): React.ReactElement {
  // const [key, setKey] = useState<number>(0);
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleReset = () => {
    setDisabled(false);
  };

  const handleSphereClick = () => {
    setDisabled(true);
  };

  return (  
      <Layout>
          <PageTitle title={t(TRANSLATIONS.magic_sphere.title)} />
          <div style={{height: '200px'}}>
            {disabled ? (
              <AskAgainButton onClick={handleReset} label={t(TRANSLATIONS.buttons.ask_again)} />
            ) : (
              <Animation animationData={animationAsk} width={'200px'} loop={true} />
            )}
          </div>
          <MagicSphere visibility={disabled} onSphereClick={handleSphereClick} />
    </Layout>
  );
}
