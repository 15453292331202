export const partyReasons = [
    {
      id: 1,
      title: "National Smile Day",
      image: "/images/smile-day.jpg",
      text: "A day to share smiles all around!",
      link: "/blog/national-smile-day",
    },
    {
      id: 2,
      title: "Hug a Friend Day",
      image: "/images/hug-friend.jpg",
      text: "Show your friends you care.",
      link: "/blog/hug-a-friend-day",
    },
    {
      id: 3,
      title: "Random Acts of Kindness Day",
      image: "/images/kindness-day.jpg",
      text: "Spread some kindness today!",
      link: "/blog/random-acts-of-kindness",
    },
    {
      id: 4,
      title: "Pizza Appreciation Day",
      image: "/images/pizza-day.jpg",
      text: "Celebrate your love for pizza.",
      link: "/blog/pizza-appreciation-day",
    },
    {
      id: 5,
      title: "Dance Like Nobody's Watching Day",
      image: "/images/dance-day.jpg",
      text: "Get moving and have fun!",
      link: "/blog/dance-like-nobody-watching-day",
    },
    ,
    {
      id: 6,
      title: "Book Lover's Day",
      image: "/images/book-lover-day.jpg",
      text: "Celebrate your love for books!",
      link: "/blog/book-lovers-day",
    },
    {
      id: 7,
      title: "Ice Cream for Breakfast Day",
      image: "/images/ice-cream-day.jpg",
      text: "Indulge in ice cream for breakfast!",
      link: "/blog/ice-cream-for-breakfast-day",
    },
    {
      id: 8,
      title: "Movie Marathon Day",
      image: "/images/movie-marathon.jpg",
      text: "Binge-watch your favorite movies.",
      link: "/blog/movie-marathon-day",
    },
    {
      id: 9,
      title: "Compliment Someone Day",
      image: "/images/compliment-day.jpg",
      text: "Brighten someone's day with kind words.",
      link: "/blog/compliment-someone-day",
    },
    {
      id: 10,
      title: "Pajama Party Day",
      image: "/images/pajama-party.jpg",
      text: "Get cozy and have a pajama party!",
      link: "/blog/pajama-party-day",
    },
    {
      id: 11,
      title: "Game Night Day",
      image: "/images/game-night.jpg",
      text: "Gather your friends for a night of games!",
      link: "/blog/game-night-day",
    },
    {
      id: 12,
      title: "Cupcake Day",
      image: "/images/cupcake-day.jpg",
      text: "Celebrate with delicious cupcakes.",
      link: "/blog/cupcake-day",
    },
    {
      id: 13,
      title: "Pet Appreciation Day",
      image: "/images/pet-appreciation.jpg",
      text: "Show love to your furry friends!",
      link: "/blog/pet-appreciation-day",
    },
    {
      id: 14,
      title: "Beach Day",
      image: "/images/beach-day.jpg",
      text: "Head to the beach and enjoy the sun!",
      link: "/blog/beach-day",
    },
    {
      id: 15,
      title: "Picnic in the Park Day",
      image: "/images/picnic-day.jpg",
      text: "Enjoy a lovely picnic outdoors.",
      link: "/blog/picnic-in-the-park-day",
    },
    {
      id: 16,
      title: "Chocolate Lovers Day",
      image: "/images/chocolate-day.jpg",
      text: "Indulge in all things chocolate!",
      link: "/blog/chocolate-lovers-day",
    },
    {
      id: 17,
      title: "Sunset Viewing Day",
      image: "/images/sunset-day.jpg",
      text: "Take time to watch the sunset.",
      link: "/blog/sunset-viewing-day",
    },
    {
      id: 18,
      title: "DIY Craft Day",
      image: "/images/craft-day.jpg",
      text: "Get creative with some DIY crafts.",
      link: "/blog/diy-craft-day",
    },
    {
      id: 19,
      title: "Hot Chocolate Day",
      image: "/images/hot-chocolate-day.jpg",
      text: "Warm up with a cup of hot chocolate.",
      link: "/blog/hot-chocolate-day",
    },
    {
      id: 20,
      title: "Nature Walk Day",
      image: "/images/nature-walk-day.jpg",
      text: "Enjoy a peaceful walk in nature.",
      link: "/blog/nature-walk-day",
    },
    {
      id: 21,
      title: "Board Game Bonanza",
      image: "/images/board-game-day.jpg",
      text: "Challenge friends to a board game tournament.",
      link: "/blog/board-game-bonanza",
    },
    {
      id: 22,
      title: "Pancake Breakfast Day",
      image: "/images/pancake-day.jpg",
      text: "Start your day with a stack of pancakes.",
      link: "/blog/pancake-breakfast-day",
    },
    {
      id: 23,
      title: "Family Movie Night",
      image: "/images/family-movie-night.jpg",
      text: "Have a fun family movie night.",
      link: "/blog/family-movie-night",
    },
    {
      id: 24,
      title: "Plant a Tree Day",
      image: "/images/plant-a-tree-day.jpg",
      text: "Help the environment by planting a tree.",
      link: "/blog/plant-a-tree-day",
    },
    {
      id: 25,
      title: "Friendsgiving",
      image: "/images/friendsgiving.jpg",
      text: "Celebrate Thanksgiving with friends.",
      link: "/blog/friendsgiving",
    },
    {
      id: 26,
      title: "Throwback Music Day",
      image: "/images/throwback-music-day.jpg",
      text: "Listen to your favorite throwback hits.",
      link: "/blog/throwback-music-day",
    },
    {
      id: 27,
      title: "Virtual Hangout Day",
      image: "/images/virtual-hangout-day.jpg",
      text: "Have a fun virtual hangout with friends.",
      link: "/blog/virtual-hangout-day",
    },
    {
      id: 28,
      title: "Puzzle Challenge Day",
      image: "/images/puzzle-day.jpg",
      text: "Solve puzzles and challenge your brain!",
      link: "/blog/puzzle-challenge-day",
    },
    {
      id: 29,
      title: "Coffee Lovers Day",
      image: "/images/coffee-lovers-day.jpg",
      text: "Celebrate your love for coffee.",
      link: "/blog/coffee-lovers-day",
    },
    {
      id: 30,
      title: "Superhero Movie Marathon Day",
      image: "/images/superhero-movie-day.jpg",
      text: "Watch all your favorite superhero movies.",
      link: "/blog/superhero-movie-marathon-day",
    },
    {
      id: 31,
      title: "Camping Under the Stars Day",
      image: "/images/camping-day.jpg",
      text: "Enjoy a night camping under the stars.",
      link: "/blog/camping-under-the-stars-day",
    },
    {
      id: 32,
      title: "Donut Day",
      image: "/images/donut-day.jpg",
      text: "Treat yourself to a delicious donut!",
      link: "/blog/donut-day",
    },
    {
      id: 33,
      title: "Self-Care Day",
      image: "/images/self-care-day.jpg",
      text: "Dedicate a day to taking care of yourself.",
      link: "/blog/self-care-day",
    },
    {
      id: 34,
      title: "Create Art Day",
      image: "/images/create-art-day.jpg",
      text: "Unleash your creativity by making art.",
      link: "/blog/create-art-day",
    },
    {
      id: 35,
      title: "Spa Day at Home",
      image: "/images/spa-day.jpg",
      text: "Pamper yourself with a home spa day.",
      link: "/blog/spa-day-at-home",
    },
  ];
  