import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { answers } from '../data/answers';
import { t } from '../translation';
import { TRANSLATIONS } from '../translation/constants/translations';

const Sphere = styled.div<{ disabled: boolean }>`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  outline: 3px solid rgba(255, 255, 255, 0.2);
  background: ${({ disabled }) => (disabled ? 'rgba(203, 96, 64, 0.7)' : 'rgba(203, 96, 64, 1)')};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin: 20px auto 80px auto;
  box-shadow: 0px 0px 150px orange;
  transition: transform 0.3s ease;

  &:hover {
    ${({ disabled }) => !disabled && `
      box-shadow: 0px 0px 80px orange;
      background: rgba(203, 96, 64, 0.9);
      font-size: 28px;
    `}
  }
`;

interface MagicSphereProps {
  visibility: boolean;
  onSphereClick: () => void;
}

export default function MagicSphere({ visibility, onSphereClick }: MagicSphereProps): React.ReactElement {
  const initText = t(TRANSLATIONS.buttons.magic_click)
  const [answer, setAnswer] = useState<string>(initText);

  useEffect(() => {
    if (!visibility) {
      setAnswer(initText);
    }
  }, [visibility]);

  const handleClick = () => {
    if (!visibility) {
      const randomAnswer = t(answers[Math.floor(Math.random() * answers.length)]);
      setAnswer(randomAnswer);
      onSphereClick();
    }
  };

  return (
    <Sphere onClick={handleClick} disabled={visibility}>
      {answer}
    </Sphere>
  );
};
