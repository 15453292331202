import { ErrorBoundary } from 'react-error-boundary';
import { Link } from "react-router-dom";
import animationLemon from '../lotties/fun-lemon.json'
import Animation from "./Animation";
import Layout from './Layout';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }) => (
    <div style={{paddingLeft: '20px'}}>
        <h1>Something went wrong.</h1>
        <p>{error?.message}</p>
        <button onClick={resetErrorBoundary} style={{padding: '10px 20px', color: 'white', backgroundColor: 'rgb(37, 113, 128)'}}>Try again</button>
        <Animation animationData={animationLemon} width={'200px'} loop={true} />
    </div>
);

export default function ErrorBoundaryPage({ children }: ErrorBoundaryProps): React.ReactElement {  
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ErrorBoundary>
      );
}